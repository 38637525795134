import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "white",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M18.3352 18.339H15.6697V14.1622C15.6697 13.1662 15.6495 11.8845 14.2807 11.8845C12.891 11.8845 12.6787 12.9683 12.6787 14.0887V18.339H10.0132V9.75H12.5737V10.9207H12.6082C12.966 10.2457 13.836 9.53325 15.1357 9.53325C17.8365 9.53325 18.336 11.3108 18.336 13.6245L18.3352 18.339ZM7.00275 8.57475C6.14475 8.57475 5.4555 7.88025 5.4555 7.026C5.4555 6.1725 6.1455 5.47875 7.00275 5.47875C7.85775 5.47875 8.55075 6.1725 8.55075 7.026C8.55075 7.88025 7.857 8.57475 7.00275 8.57475ZM8.33925 18.339H5.66625V9.75H8.33925V18.339ZM19.6687 3H4.32825C3.594 3 3 3.5805 3 4.29675V19.7033C3 20.4202 3.594 21 4.32825 21H19.6665C20.4 21 21 20.4202 21 19.7033V4.29675C21 3.5805 20.4 3 19.6665 3H19.6687Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }